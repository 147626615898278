//import "../App.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Typography } from "@mui/material";

function ProtectionDonnees() {
  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          "flex-direction": "column",
          "min-height": "100vh",
        }}
      >
        <Header />
        <section className="section section-lg section-shaped pt-100 ">
          <div className="py-lg-md d-flex">
            <div className="col px-0">
              <div>
                <div lg="6">
                  <div className="display-2">
                    {" "}
                    PROTECTION DES DONNEES PERSONNELLES
                  </div>
                  <div className="display-3">
                    {" "}
                    Finalité des données récoltées
                  </div>
                  <Typography variant="body1">
                    La clinique s'engage à respecter votre vie privée et à
                    protéger les données personnelles que vous lui communiquez.
                    En nous communiquant vos données personnelles, sur une base
                    volontaire, vous acceptez que nous les utilisions pour
                    étudier votre formulaire de demande d'admission en ligne ou
                    de contact.
                  </Typography>
                  <div className="display-3">
                    {" "}
                    Contenu des données récoltées
                  </div>
                  <Typography variant="body1">
                    Les données que nous traitons sont notamment vos nom,
                    prénom, numéro de téléphone, adresse mail, n° d'assuré et
                    autres données relatives à votre prise en charge et qui
                    figurent sur le formulaire de demande d'admission et/ou de
                    contact.
                  </Typography>
                  <div className="display-3"> Qui traite vos données ?</div>
                  <Typography variant="body1">
                    La clinique est seule destinataire de ces données
                    personnelles pour les besoins du traitement de votre demande
                    et qu'elle puisse vous recontacter par téléphone ou par
                    mail. Ces informations sont traitées par l’établissement.
                    Vos données personnelles sont conservées le temps de leur
                    utilisation et tant que vous n’en avez pas demandé la
                    suppression.
                  </Typography>
                  <div className="display-3"> Vos droits sur vos données</div>
                  <Typography variant="body1">
                    Conformément à la Loi Informatique et Libertés du 6 janvier
                    1978 et aux textes l’ayant modifiée, vous disposez d’un
                    droit d’accès, de rectification, de suppression et
                    d’opposition aux données personnelles vous concernant, qui
                    ont été collectées au titre de votre utilisation des
                    services du site internet de la clinique. Vous pouvez
                    exercer ce droit à tout moment par voie postale à l’adresse
                    suivante : Clinique Saint Roch Montfleuri - 160 route des
                    camoins - 13011 Marseille. Vos demandes de droits d’accès
                    doivent être signées et comprendre les informations
                    nécessaires à votre identification et au traitement de votre
                    demande.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
}

export default ProtectionDonnees;
