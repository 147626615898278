import Toolbar from "@mui/material/Toolbar";
import { Grid, Typography } from "@mui/material";
import { Link } from "@mui/material";
function Footer() {
  return (
    <div
      style={{
        "margin-top": "auto",
        flex: "0 0 50px",
        width: "100%",
      }}
    >
      <div className="footer bg-gradient-green">
        <Toolbar>
          <Grid container>
            <Grid item xs>
              <Typography color="secondary.contrastText">
                @ 2022 Clinique Saint Roch Montfleuri
              </Typography>
            </Grid>
            <Grid item xs>
              <Link href="/#/mentions-legales">Mentions légales</Link>
            </Grid>
            <Grid item xs>
              <Link href="/#/protection-des-donnees">
                Protection des données
              </Link>
            </Grid>
            <Grid item xs>
              <Link href="/#/contact/autres">Nous rejoindre</Link>
            </Grid>
          </Grid>
        </Toolbar>
      </div>
    </div>
  );
}
export default Footer;
