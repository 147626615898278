import "../App.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Grid, List, ListItem } from "@mui/material";
import art from "../Assets/Galerie/PEINTURE/IMG_3084.jpg";
import muscu from "../Assets/Galerie/SPORT/IMG_3093.jpg";
import biblio from "../Assets/Galerie/RTMS/IMG_2970.jpg";

import * as React from "react";
import { Box } from "@mui/system";

function Activites() {
  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          "flex-direction": "column",
          "min-height": "100vh",
        }}
      >
        <Header />
        <div className="position-relative">
          <section className="section section-lg section-shaped pt-100 ">
            <div className="py-lg-md d-flex">
              <div className="col px-0">
                <div>
                  <div lg="6">
                    <Grid
                      container
                      xs={12}
                      justifyContent="center"
                      spacing={4}
                      direction="column"
                    >
                      <h1 className="display-1">Activités</h1>

                      <Box width="75%" m="auto" pb="100px">
                        <p className="display-4" align="left">
                          Activités thérapeutiques sportives
                        </p>
                        <Grid container>
                          <Grid item xs={6}>
                            <div align="left">
                              Nos professionnels organisent quotidiennement en
                              complément de votre prise en charge des groupes
                              d'activités, notamment sportives, telles que :
                            </div>
                            <List>
                              <ListItem>Le Yoga</ListItem>
                              <ListItem>Le Renforcement Musculaire</ListItem>
                              <ListItem>La Marche</ListItem>
                              <ListItem>La Gym Douce</ListItem>
                              <ListItem>Les Pilates</ListItem>
                              <ListItem>
                                Des prises en charge individuelles
                              </ListItem>
                            </List>
                          </Grid>
                          <Grid item xs={6}>
                            <img alt="" src={muscu} height="400" />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box width="75%" m="auto" pb="100px">
                        <p className="display-4" align="left">
                          Activités thérapeutiques artistiques
                        </p>
                        <Grid container direction="row">
                          <Grid item xs={6}>
                            <div align="left">
                              Vous pouvez également laisser libre cours à votre
                              fibre artistique au travers d'ateliers
                              artistiques, tels que :
                            </div>
                            <List>
                              <ListItem>L'Ecoute Musicale</ListItem>
                              <ListItem>La Musico-Thérapie</ListItem>
                              <ListItem>
                                L'Atelier d'Expression Artistique
                              </ListItem>
                              <ListItem>L'Art-Thérapie</ListItem>
                            </List>
                          </Grid>
                          <Grid item xs={6}>
                            <img alt="" src={art} height="400" />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box width="75%" m="auto" pb="100px">
                        <p className="display-4" align="left">
                          Activités thérapeutiques
                        </p>
                        <Grid container direction="row">
                          <Grid item xs={6}>
                            <div align="left">
                              Des ateliers thérapeutiques vous sont proposés
                              pour vous accompagner tout au long de votre prise
                              en charge. Ainsi, vous pourrez participer à des
                              ateliers variés au cours de votre séjour, tels que
                              :
                            </div>
                            <List>
                              <ListItem>
                                Des Groupes de Parole/d'Ecriture
                              </ListItem>
                              <ListItem>Des Karaokés</ListItem>
                              <ListItem>
                                Des Ateliers d'Affirmation de Soi
                              </ListItem>
                              <ListItem>De la Sophrologie</ListItem>
                              <ListItem>Du chant</ListItem>
                              <ListItem>De la Relaxation</ListItem>
                              <ListItem>De l'Expression Corporelle</ListItem>
                              <ListItem>De la Zoo Thérapie</ListItem>
                              <ListItem>Des Jeux en Exterieur</ListItem>
                              <ListItem>Le Café Philo</ListItem>
                              <ListItem>
                                Une atelier Bien être et Beauté
                              </ListItem>
                            </List>
                          </Grid>
                          <Grid item xs={6}>
                            <img alt="" src={biblio} height="400" />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Activites;
