import "../App.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import { Card, CardContent, Grid, Link, List, ListItem } from "@mui/material";

const steps = ["Admission", "Séjour", "Sortie"];

export function HorizontalNonLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%", mb: 10 }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
      <div>
        {activeStep === 0 && (
          <>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              columns={{ xs: 8, sm: 8, md: 12 }}
            >
              <Grid item container xs={8} sm={8} md={4}>
                <Card sx={{ maxWidth: 500 }}>
                  <CardContent>
                    <Typography
                      variant="h5"
                      color="primary.main"
                      sx={{ pb: 1 }}
                    >
                      Admission Administrative
                    </Typography>
                    <Grid item>
                      <Typography variant="body2" align="left" sx={{ pb: 1 }}>
                        Suite à une demande formulée par votre médecin, vous
                        serez contacté par le service administratif des
                        admissions qui vous proposera une date d'entrée.
                      </Typography>
                      <Typography variant="body2" align="left" sx={{ pb: 1 }}>
                        Les admissions sont faites par le bureau des entrées sur
                        rendez-vous du lundi au vendredi de 13h30 à 16h30, hors
                        cas d'urgence.
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item container xs={8} sm={8} md={4}>
                <Card sx={{ maxWidth: 500 }}>
                  <CardContent>
                    <Typography
                      variant="h5"
                      color="primary.main"
                      sx={{ pb: 1 }}
                    >
                      Admission Médicale
                    </Typography>
                    <Typography variant="body2" align="left" sx={{ pb: 1 }}>
                      Le jour de votre admission, le médecin psychiatre qui sera
                      en charge des admissions vous recevra en entretien, il
                      confirmera votre entrée en remplissant un dossier médical
                      unique et personnalisé à votre nom.
                    </Typography>
                    <Typography variant="body2" align="left" sx={{ pb: 1 }}>
                      Dès la fin de cet entretien, un membre de l'équipe
                      soignante vous présentera l'établissement et son
                      fonctionnement, puis l'infirmier de l'unité dans laquelle
                      vous serez hospitalisé vous accompagnera jusqu'à votre
                      chambre.
                    </Typography>
                    <Typography variant="body2" align="left">
                      Le lendemain, votre psychiatre traitant établira avec vous
                      le projet thérapeutique personnalisé. Le lendemain de
                      votre arrivée ou au maximum 48 heures après votre
                      admission, un de nos médecins généralistes vous examinera.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Typography variant="body2" align="left">
              Veuillez apporter :
            </Typography>
            <Typography variant="body2" align="left">
              <List>
                <ListItem>- Votre carte d'identité</ListItem>
                <ListItem>- Votre carte vitale</ListItem>
                <ListItem>
                  - Votre carte de mutuelle ou votre attestation CMU
                </ListItem>
                <ListItem>
                  - Votre dernière ordonnance de traitement en cours
                </ListItem>
                <ListItem>
                  - Certificat d'aptitude à la pratique sportive pour la
                  participation aux ateliers sport, taï-chi, randonnée, danse,
                  balnéothérapie...
                </ListItem>
                <ListItem>- Votre chéquier pour :</ListItem>
                <List>
                  <ListItem>
                    + acompte sur les frais d'hospitalisation (Forfait
                    journalier, éventuellement chambre particulière, TV, Wifi)
                  </ListItem>
                  <ListItem>
                    + caution de 20€ pour les clés de la chambre ou pour le
                    badge d'accès au service. En cas de perte celle-ci sera
                    encaissée et une nouvelle vous sera demandée
                  </ListItem>
                </List>
              </List>
            </Typography>
          </>
        )}
        {activeStep === 1 && (
          <Box>
            <Typography variant="body2" sx={{ pb: 1 }}>
              <Link href="/#/presentation/equipe" color="primary.main">
                Le médecin et le personnel soignant{" "}
              </Link>
              vous informent régulièrement de votre état de santé, des examens
              et des
              <Link href="/#/services/activites" color="primary.main">
                {" "}
                traitements nécessaires.
              </Link>
            </Typography>
            <Typography variant="body2">
              Tous les 2 mois, un bilan de suivi est réalisé. A cette occasion,
              une appréciation de votre évolution, un rappel des objectifs et un
              réaménagement éventuel de la prise en charge sont effectués.{" "}
            </Typography>
            <Typography variant="body2" sx={{ pb: 1 }}>
              Le repas est servi à 12h45, il est pris au restaurant de la
              Clinique Saint Roch Montfleuri. Les repas sont proposés en
              fonction de votre projet thérapeutique et des plannings
              d'activités.
            </Typography>
            <Typography variant="body2">
              La prise de votre traitement médical est sous votre responsabilité
            </Typography>
          </Box>
        )}
        {activeStep === 2 && (
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              m: "auto",
            }}
          >
            <Typography variant="body2" sx={{ pb: 1 }}>
              L'arrêt de la prise en charge fait suite à un accord entre vous,
              le médecin, l'équipe soignante et fait partie intégrante du projet
              thérapeutique.
              <Typography variant="body2" sx={{ pb: 1 }}>
                Vous pouvez quitter l'établissement à tout moment sur simple
                demande. Toutefois, si le médecin estime que cette sortie
                s'effectue de façon prématurée, vous devrez signer un formulaire
                dégageant la responsabilité de l'établissement.
              </Typography>
              <Typography variant="body2">
                L'absentéisme, une conduite inadaptée ou violente peut conduire
                l'équipe à revoir votre projet de soin et/ou reconsidérer votre
                prise en charge.
              </Typography>
            </Typography>
          </Box>
        )}
      </div>
    </Box>
  );
}

function HospitalisationComplete() {
  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          "flex-direction": "column",
          "min-height": "100vh",
        }}
      >
        <Header />
        <div className="position-relative">
          <section className="section section-lg section-shaped pt-100 ">
            <div className="py-lg-md d-flex">
              <div className="col px-0">
                <div>
                  <div lg="6">
                    <Box m="auto" width="75%">
                      <h1 className="display-1">Hospitalisation Complète</h1>
                      <Box mb={10}>
                        <p className="display-4">Objectifs</p>
                        <Typography variant="body2">
                          <Typography
                            variant="body2"
                            display="flex"
                            justifyContent="flex-start"
                          >
                            L’objectif de l’hospitalisation est de préparer la
                            sortie du patient et son retour à la vie active.
                            Dans cette perspective, le traitement comprend deux
                            phases :
                          </Typography>
                          <List>
                            <ListItem>
                              - Une réponse immédiate à la souffrance.
                            </ListItem>
                            <ListItem>
                              - Une réévaluation quotidienne pour éviter les
                              rechutes et récidives et limiter les
                              complications.
                            </ListItem>
                          </List>
                        </Typography>
                      </Box>

                      <Box mb={10}>
                        <p className="display-4">Parcours</p>
                        <HorizontalNonLinearStepper />
                      </Box>
                      <Box mb={10}>
                        <p className="display-4">Règlement</p>
                        <Typography variant="body2" align="left">
                          <Typography
                            variant="body2"
                            style={{ lineHeight: "24px" }}
                          >
                            Il est recommandé de ne pas apporter de valeurs ou
                            de documents personnels non nécessaires à la prise
                            en charge. Un vestiaire est prévu pour ranger les
                            affaires personnelles de chaque patient.{" "}
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ lineHeight: "24px" }}
                          >
                            La consommation ou l’introduction de boissons
                            alcoolisées ou de substances toxiques sont
                            strictement interdites.
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ lineHeight: "24px" }}
                          >
                            L’usage du tabac n’est pas autorisé à l’intérieur
                            des locaux.{" "}
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ lineHeight: "24px" }}
                          >
                            Une tenue vestimentaire ainsi qu’une attitude
                            correcte et respectueuse envers les autres sont
                            impératives.{" "}
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ lineHeight: "24px" }}
                          >
                            Nous vous demandons de respecter les locaux et le
                            matériel mis à votre disposition. Toute dégradation
                            vous sera facturée.{" "}
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ lineHeight: "24px" }}
                          >
                            Le service d’accueil de jour étant un lieu de soins,
                            l’usage du téléphone portable n’est pas autorisé
                            pendant les séances de soins.{" "}
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ lineHeight: "24px" }}
                          >
                            Vous ne pourrez pas recevoir de visites pendant le
                            temps de votre prise en charge.{" "}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ pb: 2 }}
                            style={{ lineHeight: "24px" }}
                          >
                            Vous êtes inscrit dans un programme de soins
                            personnalisé, il est important d’être assidu aux
                            séances prévues et de respecter les horaires du
                            planning. Chaque absence sera reprise avec un
                            soignant et la poursuite de la prise en charge
                            pourra être réévaluée et rediscutée.
                          </Typography>{" "}
                        </Typography>

                        <Typography variant="h5">
                          Le non-respect de ces consignes entrainerait la
                          rupture du contrat de soin.
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default HospitalisationComplete;
