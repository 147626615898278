//import "../App.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Box, Typography } from "@mui/material";
import Investissements from "../Assets/L-etiquette-Finance-par.png";

function Legal() {
  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          "flex-direction": "column",
          "min-height": "100vh",
        }}
      >
        <Header />
        <section className="section section-lg section-shaped pt-100 ">
          <div className="py-lg-md d-flex">
            <div className="col px-0">
              <div>
                <div lg="6">
                  <Box width="75%" m="auto" pb="50px">
                    <Box className="display-2" pb="50px">
                    MENTIONS LÉGALES
                      <Box className="display-3">1. IDENTITÉ</Box>
                      <Box pb="20px">
                        <Typography variant="body1">
                        Conformément aux dispositions de la loi n°2004-575 du 21 juin 2024 pour la confiance dans l'économie numérique, nous vous informons que le présent site est la propriété de la clinique du Golfe.
                        </Typography>
                        <Typography variant="body1">
                        CLINIQUE MONTFLEURI SAINT ROCH
Forme juridique : SA
160 route des camoins - 13011 MARSEILLE
Téléphone : 04.91.43.01.20
Adresse mail  : direction@saintroch-montfleuri.fr
N°SIREN : 054 805 577
N°RCS : Saint-Brieuc B 334 686 383, immatriculée depuis le 22-05-1986 au greffe de Saint-Brieuc
N° SIRET : 054 805 577 00027
N° TVA intracommunautaire : FR 94 054 805 577
Capital social : 300.000 €
Adresse du siège social : 160 route des camoins - 13011 MARSEILLE
Nom et adresse de l'autorité ayant délivré l'autorisation d'exercer : Agence Régionale de Santé PACA - 132 Bd de Paris - 13003 MARSEILLE


                        </Typography>
                        <Typography variant="body1">
                        Informations relatives à l'hébergement du site : .
Nom de l'hébergeur : Scaleway
Raison sociale : Scaleway
Adresse : 8 rue de la Ville L'Evêque - 75008 PARIS
Tel : 01.84.13.00.00
                        </Typography>
                      </Box>
                      <Box pb="20px">
                        <div className="display-3">2. RESPONSABILITÉ</div>
                        <Typography variant="body1">
                        Tout utilisateur, ayant accès à ce site, s'engage à se conformer aux conditions d'utilisations ci-après :
Informations accessibles via ce site web : L’établissement s'efforce d'assurer une information fiable et à jour de son site. Néanmoins des erreurs ou omissions peuvent survenir. Les informations transmises ne le sont qu'à titre indicatif.
Les informations contenues sur ce site sont fournies " en l'état ", sans aucune garantie. L'utilisateur devra donc s'assurer de l'exactitude des informations contenues sur ce site auprès de l'accueil de l'établissement. L’établissement ne pourra être tenu responsable de toute utilisation qui pourrait en être faite et de tout préjudice direct ou indirect pouvant en découler. La direction se réserve le droit de modifier ce site et les informations qu'il contient et ce à tout moment.
Les sites qui pourraient avoir un lien hypertexte avec ce site ne sont pas sous contrôle de l’établissement. Par conséquent, L’établissement ne saurait être responsable de leur contenu. Les risques liés à l'utilisation de ce site n'incombent qu'à leur utilisateur qui doit se conformer à leurs conditions d'utilisation
                        </Typography>
                        <Typography>
                        <div className="display-3">3. PROTECTION DES DONNÉES PERSONNELLES</div>
Les dispositions ci-dessous s’appliquent aux internautes lors de leur navigation sur le présent site et à l’occasion de leur utilisation (facultative) des formulaires de contact.
Le site assure la collecte et le traitement des Données à caractère personnel dans le respect de la vie privée et conformément à la législation relative à la protection des données personnelles (loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés et Règlement Général sur la Protection des Données du 27 avril 2016).
Le responsable de la collecte et du traitement de vos Données personnelles est la Clinique Montfleuri Saint Roch.
Les Données personnelles traitées sont celles collectées par le biais du formulaire de contact renseigné par le visiteur du site Internet : ces données sont transmises à l’établissement et susceptibles d’apporter les informations et réponses sollicitées.
Les Données personnelles recueillies sont les suivantes : nom, prénom, numéro de téléphone, adresse, • Toute autre information saisie dans les champs libres mis à disposition.
Les Données personnelles collectées restent au sein de la Clinique Montfleuri Saint Roch. Il n’est procédé à aucun export de ces données, en particulier vers les pays qui ne sont membres ni de l’Union Européenne ni de l’Espace Économique Européen.
Toutes les mesures de sécurité techniques et organisationnelles nécessaires à la protection de vos Données personnelles sont mises en œuvre.
Les données personnelles collectées ne sont conservées que le temps nécessaire aux finalités poursuivies, sauf à des fins statistiques ou historiques, et toujours dans les limites prescrites par la réglementation ou recommandées par les autorités de contrôle.
Le visiteur du site dont les données sont traitées bénéficie des droits prévus aux articles 38 et suivants de la loi « informatique et libertés » modifiée et aux articles 15 et suivants du Règlement Général sur la Protection des données dont celui de s’opposer, pour des motifs légitimes, à ce que les données à caractère personnel le concernant fassent l’objet d’un traitement ou soient utilisées à des fins de prospection.
Il a également la possibilité d’accéder à ses données à caractère personnel et d’en demander la rectification, l’effacement, la limitation ainsi que la portabilité.
Le Délégué à la Protection des Données peut être contacté pour toute question en lien avec le traitement des Données personnelles. En cas de demande d’exercice des droits listés précédemment, des éléments complémentaires peuvent vous être demandés afin d’établir votre identité (par exemple, photocopie d’une pièce justificative).
La demande doit être adressée par courrier électronique (dpo@cliniquelacerisaie.fr) ou par écrit à l’adresse suivante :
Clinique Montfleuri Saint Roch
Madame La Déléguée à la Protection des Données
160 route des camoins - 13011 MARSEILLE
La Commission Nationale de l’Informatique et des Libertés (CNIL), située au 3 Place de Fontenoy TSA 80715 75334 Paris cedex 07 est l’organisme chargé de recevoir toute réclamation relative à l’exercice de vos droits.
Par ailleurs, conformément aux dispositions de l’article 111 du décret n°2019-536 du 29 mai 2019, nous vous informons que les données de santé collectées dans le cadre d’un séjour au sein d’un Etablissement de Santé pourront être réutilisées à des fins de recherche, d’étude ou d’évaluation. Ces données sont dans ce cas préalablement rendues non directement identifiantes. Vous disposez à propos de ce traitement des droits énoncés ci-dessus.
Vous pouvez adresser un courrier à la Directrice de l'établissement afin de saisir la Commission de Conciliation de la Commission des Usagers (CDU) au 160 route des camoins - 13011 MARSEILLE.
L'intégralité des plaintes et réclamations sont prises en compte par l'établissement et traitées dans le respect de vos droits (décret n°2005-213 du 2 mars 2005).
                        </Typography>
                        <Typography variant="body1">
                        <div className="display-3">4. DROITS D'AUTEUR</div>
                        L'utilisation et la copie des informations (documents et éléments graphiques) disponibles sur ce site sont autorisées sous réserve :

De reproduire sur toute copie la mention du droit d'auteur
De rappeler sur toute copie le présent avertissement
De n'utiliser ou copier de telles informations qu'à des fins d'information, à l'exclusion de toutes fins commerciales
De ne modifier de quelque manière que ce soit les informations contenues sur ce site
De ne pas utiliser ou copier les éléments graphiques disponibles sur ce site séparément du texte associé
L'ensemble des droits non expressément concédés aux termes des présentes sont réservés à l’établissement.
Marques : Tout terme ou logo disponible sur ce site sont susceptibles d'être protégés par les droits relatifs à la propriété intellectuelle ou par une marque. A ce titre, leur mention sur ce site n'accorde en aucune manière une licence ou un droit d'utilisation quelconque desdits termes ou logos. Avant toute utilisation ou reproduction de ces derniers l'obtention de l'accord préalable et écrit de leur auteur et /ou propriétaire est donc nécessaire.
Liens hypertextes avec des sites tiers : Tout lien avec ce site ou utilisant les logos disponibles sur ce site ne pourra se faire qu'après accord écrit de la direction de l’établissement.
Tout manquement à ces règles est de nature à être sanctionné conformément aux articles M. 335-2 et suivants du Code de la Propriété Intellectuelle.
</Typography>
                    <Box className="display-2">INDEX D'EGALITE H/F</Box>

                    <Typography variant="body1">
                      En accord avec la législation, la clinique a calculé son{" "}
                      <b>index égalité H/F</b>, dont le détail du calcul peut
                      être retrouvé{" "}
                      <a href="https://egapro.travail.gouv.fr/index-egapro/recherche?query=SOCIETE+ANONYME+SAINT+ROCH&regionCode=93">
                        sur le site du gouvernement.
                      </a>
                    </Typography>
                  </Box>
                  </Box>
                  <Box className="display-2">INVESTISSEMENTS DU QUOTIDIEN</Box>

                  <Typography variant="body1">
                    Des Investissements du quotidien ont été financés par l'UE
                    <div>
                      <img
                        src={Investissements}
                        alt="Investissements France Relance"
                        height={250}
                      />
                    </div>
                  </Typography>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default Legal;
