import "../App.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Grid, List, ListItem } from "@mui/material";

import * as React from "react";
import { Box } from "@mui/system";
import etp from "../Assets/Galerie/SENSIBILISATION/IMG_3105.jpg";
import rtms from "../Assets/Galerie/RTMS/IMG_2970.jpg";
import rv from "../Assets/Galerie/REALITE VIRTUELLE/IMG_3016.jpg";
import psycho from "../Assets/Galerie/MASSAGE/IMG_2964.jpg";
import sommeil from "../Assets/Galerie/EXTERIEUR/IMG_3153.jpg";
import tdah from "../Assets/Galerie/KARAOKE/IMG_3044.jpg";

function Pathologies() {
  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          "flex-direction": "column",
          "min-height": "100vh",
        }}
      >
        <Header />
        <div className="position-relative">
          <section className="section section-lg section-shaped pt-100 ">
            <div className="py-lg-md d-flex">
              <div className="col px-0">
                <div>
                  <div lg="6">
                    <Grid
                      container
                      xs={12}
                      justifyContent="center"
                      spacing={4}
                      direction="column"
                    >
                      <h1 className="display-1">Traitements aux pathologies</h1>

                      <Box width="75%" m="auto" pb="100px">
                        <p className="display-4" align="left">
                          Education Thrapeutique (ETP)
                        </p>
                        <Grid container direction="row">
                          <Grid item xs={6}>
                            <div align="left">
                              Deux programmes sont actuellement en place :
                            </div>
                            <List>
                              <ListItem>
                                Dépression et états dépressifs
                              </ListItem>
                              <ListItem>Troubles anxieux graves</ListItem>
                            </List>
                            <div
                              align="left"
                              style={{ "margin-bottom": "10px" }}
                            >
                              Ils font partie intgrante de notre parcours de
                              soins en hospitalisation temps complet et temps
                              partiel, pour les patients répondant aux critères
                              requis et volontaires pour participer à ces
                              ateliers
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <img alt="" src={etp} height="400" />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box width="75%" m="auto" pb="100px">
                        <p className="display-4" align="left">
                          La Stimulation Magnétique Trans Cranienne (rTMS)
                        </p>
                        <Grid container direction="row">
                          <Grid item xs={6}>
                            <div align="left">
                              Cet outil de stimulation cérébrale non invasive
                              complète l'arsenal thérapeutique dans de
                              nombreuses indications comme la dépression, les
                              TOC, les hallucinations...
                            </div>
                            <div
                              align="left"
                              style={{ "margin-bottom": "10px" }}
                            >
                              Notre équipe médicale utilise cette méthode selon
                              des protocoles spécifiques à chaque pathologie
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <img alt="" src={rtms} height="400" />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box width="75%" m="auto" pb="100px">
                        <p className="display-4" align="left">
                          Les phobies et la réalité virtuelle
                        </p>
                        <Grid container direction="row">
                          <Grid item xs={6}>
                            <div align="left">
                              Cette technique permettant aux personne d'être,
                              d'interagir et d'apprendre dans des environnements
                              interactifs de synthèse créés par ordinateur, en
                              utilisant des visiocasques et des capteurs de
                              mouvements, est une avancée considérable dans la
                              prise en charge de pathologies très répandues,
                              telles que les phobies, les troubles du
                              comportement alimentaire, les addictions...
                            </div>
                            <div
                              align="left"
                              style={{ "margin-bottom": "10px" }}
                            >
                              Dans notre pôle sont traités tout particulièrement
                              les acrophobies, les amaxophobies, les
                              claustrophobies, l'agoraphobie...
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <img alt="" src={rv} height="400" />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box width="75%" m="auto" pb="100px">
                        <p className="display-4" align="left">
                          Nouvelles psychothérapies
                        </p>
                        <Grid container direction="row">
                          <Grid item xs={6}>
                            <div align="left">
                              Elles complètent dans notre parcours de soin cette
                              approche non médicamenteuse basée principalement
                              sur la pleine conscience, le retraitement par les
                              mouvements oculaires afin de diminuer le stress,
                              l'anxiété, le ressenti de traumatismes...
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <img alt="" src={psycho} height="400" />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box width="75%" m="auto" pb="100px">
                        <p className="display-4" align="left">
                          Troubles du sommeil
                        </p>
                        <Grid container direction="row">
                          <Grid item xs={6}>
                            <div align="left">
                              Le sommeil est essentiel, et sa qualité est une
                              priorité. Nos équipes médicales et paraméédicales
                              vous accueillent dans notre unité sommeil dédiée
                              afin d'évaluer et traiter vos troubles du sommeil
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <img alt="" src={sommeil} height="400" />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box width="75%" m="auto" pb="100px">
                        <p className="display-4" align="left">
                          Trouble déficit de l'attention avec ou sans
                          hyperactivité (TDAH)
                        </p>
                        <Grid container direction="row">
                          <Grid item xs={6}>
                            <div align="left">
                              Le TDAH est un trouble de la régularisation
                              attentionnelle associé à une impulsivité et une
                              hyperactivité psychomotrice pouvant perturber la
                              régularisation éémotionnelle, le sommeil et
                              l'alimentation.
                            </div>
                            <div
                              align="left"
                              style={{ "margin-bottom": "10px" }}
                            >
                              Le neurofeedback est une psychothérapie de
                              remédiation cognitive permettant d'apprendre à
                              auto réguler son activité cérébrale grâce à un
                              retour d'informations (feedback) en temps réel sur
                              l'écran de l'ordinateur
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <img alt="" src={tdah} height="400" />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Pathologies;
